// 
// authentication.scss
//


// authentication

.authentication-bg{
    .auth-logo{
        .logo{
            margin: 0px auto;
        }
    }

    .carousel.slide{
        height: 100%;

        .carousel-inner{
            height: 100%;

            .carousel-item.active{
                height: 100%;
                img{
                    object-fit: cover;
                    height: 100vh;
                }
            }
        }
    }

}



// .fixed-background {
//     // background: url("/assets/images/myds-login-back.svg") no-repeat center center fixed;
//     // background-size: cover;
//     // width: 100%;
//     // height: 100%;
//     // position: fixed;
//     // top: 0;
//     // right: 0;
//     // bottom: 0;
//     // left: 0;
// }


// signin card title

.signin-other-title{
    position: relative;
    &:after{
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        right: 0;
        background-color: $border-color;
        top: 10px;
    }

    .title{
        display: inline-block;
        position: relative;
        z-index: 9;
        background-color: $card-bg;
        padding: 2px 16px;
    }
}


// auth carousel

.auth-carousel{
    &.owl-theme {
        .owl-dots {
            .owl-dot {
                span{
                    background-color: $card-bg;
                }
                &.active{
                    span{
                        background-color: $primary;
                    }
                }
            }
        }
    }
}